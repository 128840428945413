.grid-background {
  position: relative;
  min-height: 100vh;
  padding: 6rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 0; /* Ensure this is at the back */
}


.grid-background::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80vw; /* Adjust to viewport width for responsiveness */
  height: 80vw; /* Make it proportional to width */
  max-width: 100vh; /* Limit the size on larger screens */
  max-height: 100vh;
  background: radial-gradient(circle, 
    rgba(0, 0, 0, 0.1) 1%, 
    transparent 11%, 
    rgba(0, 0, 0, 0.1) 2%, 
    transparent 21%, 
    rgba(0, 0, 0, 0.1) 3%, 
    transparent 31%, 
    rgba(0, 0, 0, 0.1) 4%, 
    transparent 41%, 
    rgba(0, 0, 0, 0.1) 5%, 
    transparent 51%,
    rgba(0, 0, 0, 0.1) 6%, 
    transparent 61%,
    rgba(0, 0, 0, 0.1) 7%, 
    transparent 71%);
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 2), rgba(0, 0, 0, 0));
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -1; /* Ensure this is behind all other elements */
  opacity: 0.2; /* Adjust the opacity for subtlety */
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .grid-background {
    padding: 4rem 1rem;
  }

  .grid-background::before {
    width: 90vw; /* Make it larger for smaller screens */
    height: 90vw; 
  }
}

@media (max-width: 480px) {
  .grid-background {
    padding: 3rem 1rem;
  }

  .grid-background::before {
    width: 100vw; /* Fill the screen width */
    height: 100vw;
    opacity: 0.15; /* Slightly less opacity for smaller screens */
  }
}
