@tailwind base;
@tailwind components;
@tailwind utilities;


.stroke-text {
    -webkit-text-stroke: 2px white; /* Adjust the width as needed */
  }

  html, body {
    background-color: white;
    min-height: 100%; /* Ensure the body takes up the full height of the viewport */
    overscroll-behavior-y: contain; /* Minimize overscroll bounce effect */
  }
  